/* RemoveDetails.css */

body, html {
     margin: 0;
    padding: 0;
    height: 100%;
    font-family: Arial, sans-serif;
    /* background: linear-gradient(135deg, #74ebd5 0%, #ACB6E5 100%); */
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
  }
  
  .container {
    max-width: 400px;
    margin: 50px auto;
    padding: 30px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
  }
  
  /* Headings */
  h2 {
    color: #333;
    margin-bottom: 20px;
    font-size: 24px;
  }
  
  /* Inputs */
  input[type="text"] {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
  }
  
  /* Buttons */
  button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    margin: 10px 0;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  .nav-background {
    background-color: black;
    padding: 10px;
    height: 15vh;
  }
  
  /* Message */
  p {
    color: #555;
    font-size: 14px;
  }
  
  /* Loader buttons */
  button.loading {
    background-color: #f39c12;
    cursor: wait;
  }
  
  /* Recaptcha container */
  #recaptcha-container {
    margin-top: 20px;
  }